import React, { useState, useEffect, useRef,forwardRef, useImperativeHandle } from "react";
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useSelector, useDispatch } from 'react-redux';


const GoogleSearchAutocomplete = forwardRef((props, ref) => {
    const resetSearch  = useSelector(state => state.common.resetSearch);
    const { onReset, touchHandler, value } = props;
    const location = useLocation();
    const paramsData = queryString.parse( location.search );
    const [query, setQuery] = useState( 
      paramsData ? 
      ( paramsData.field_country_value ? paramsData.field_country_value : "") :
      (value ? value : '') )
      ;
    const autoCompleteRef = useRef(null);
    const autoCompleteRef2 = useRef(null);

    let autoComplete;

function handleScriptLoad(updateQuery, autoCompleteRef) {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    //{ types: ["(cities)"], componentRestrictions: { country: "us" } }
  );
  autoComplete.setFields(["address_components", "formatted_address", "adr_address", "geometry"]);
  autoComplete.addListener("place_changed", () =>
    handlePlaceSelect(updateQuery)
  );
}

useEffect(()=>{
  if(resetSearch){
    setQuery("");
  }
  if( onReset ){
    setQuery("");   
  }
   
  if( value )
    setQuery( value );
},[onReset, value, resetSearch]);

// resetLocation = () =>{
//   setQuery("");
// }

async function handlePlaceSelect(updateQuery) {
    
  const addressObject = autoComplete.getPlace();
  const query = addressObject.formatted_address;
  if(autoLoad){
    onInput({
      query
  })
    return 
  }
  updateQuery(query);

  onInput( addressObject.formatted_address ? addressObject.formatted_address : null);
}

    const { onInput, autoLoad } = props;
    
  // useEffect(() => {
  //   //handleScriptLoad(setQuery, autoCompleteRef);
  // }, []);

  const _handelQuery = ( event )=>{
    touchHandler(true);
    handleScriptLoad(setQuery, autoCompleteRef);
      if( event.target.value === '' ){
          onInput({

          });
      }
    //console.log( event.target.value );
    setQuery(event.target.value);
  }

  const handleValueBlur =(e)=>{
    //console.log(autoCompleteRef2.current.value, '556')
    props.onblurEvent(autoCompleteRef2.current.value)
  }

  useImperativeHandle(ref, () => ({
  
    clearValues() {
      autoCompleteRef2.current.value = '';
     //setQuery('');
    }

  }));

  return (
    // <div className="search-location-input">
    //   <input
    //     ref={autoCompleteRef}
    //     onChange={event => setQuery(event.target.value)}
    //     placeholder="Enter a City"
    //     value={query}
    //   />
    // </div>
    autoLoad ?
    
        <input 
            type="" 
            name="search_key" 
            placeholder="Location and Country" 
            ref={autoCompleteRef2}
            className={props.isrequired ? 'mandatory':''}
            onChange={event => { 
                if( autoLoad)
                  handleScriptLoad(setQuery, autoCompleteRef2)
                    
                setQuery(event.target.value); 
              } }
            onBlur={handleValueBlur}
            value={query}
            />
   
    :
    <div className="form-group widthsm">
        <input 
            type="" 
            name="search_key" 
            placeholder="Search by location " 
            ref={autoCompleteRef}
            onChange={ _handelQuery }
            value={query}/>
    </div>
  );
});

export default GoogleSearchAutocomplete;
